export default {
  setToken(state, payload) {
    state.token = payload.token;
  },
  setUserType(state, payload) {
    state.userType = payload.userType;
  },
  setUserId(state, payload) {
    state.userId = payload.userId;
  },
  setFullName(state, payload) {
    state.fullName = payload.fullName;
  },
  setUserStatus(state, payload) {
    state.userStatus = payload;
  }
};

import axios from 'axios';

export default class ApiService {
  constructor(initial_token, router, store) {
    this.router = router;
    this.store = store;
    this.rootUrl = '/api';
    this.http = axios.create({
      baseURL: `${process.env.VUE_APP_SERVER_URL}`,
    });
    this.http.defaults.headers.common['content-type'] =
      'application/json; charset=utf-8';
    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // eslint-disable-next-line
          console.log('user creds no longer valid - logout');
          store.dispatch('logOut');
          this.setAuth(null, true);
        } else if (error.response.status === 403) {
          return Promise.reject(
            new Error('User not authorized to access feature')
          );
        } else {
          return Promise.reject(error.response);
        }
      }
    );
    // page was refreshed while authorized so reset http authorize
    if (initial_token) {
      this.setAuth(initial_token);
    }
  }

  // authorization change so change http authorization and route to login page
  setAuth(token, is401Redirect) {
    if (token && token !== 'null') {
      // eslint-disable-next-line
      this.http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      this.http.defaults.headers.common['Authorization'] = null;
      if (
        is401Redirect &&
        this.router.currentRoute._rawValue.name != 'reset-password' &&
        this.router.currentRoute._rawValue.name != 'home'
      ) {
        // clean up open modals
        document.querySelector('body').classList.remove('modal-open');
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
        // if route does NOT contain query or path parameters, set the redirectName
        let redirectName = undefined;
        if (
          Object.keys(this.router.currentRoute._rawValue.params).length == 0 &&
          Object.keys(this.router.currentRoute._rawValue.query).length == 0 &&
          this.router.currentRoute._rawValue.name != 'login'
        ) {
          redirectName = this.router.currentRoute._rawValue.name;
        }
        this.router.push({
          name: 'login',
          query: {
            redirectName: redirectName,
          },
        });
      } else {
        this.router.push({
          name: 'login',
        });
      }
    }
  }

  authApi() {
    const self = this;
    const url = `${this.rootUrl}/login`;
    return {
      async login(uname, pwd) {
        // make sure interceptor isn't evoked
        const call = axios.create({
          baseURL: `${process.env.VUE_APP_SERVER_URL}`,
        });
        return await call.post(url, {
          userName: uname,
          password: pwd,
        });
      },
      async extn_auth(auth0_token) {
        const call = axios.create({
          baseURL: `${process.env.VUE_APP_SERVER_URL}`,
        });
        call.defaults.headers.common['Authorization'] = `Bearer ${auth0_token}`;
        return await call.post(`${url}/extnlogin`, null);
      },
      async reset_password(new_pwd) {
        return await self.http.post(`${url}/password/reset`, {
          newPassword: new_pwd,
        });
      },
      async chg_password(old_pwd, new_pwd) {
        return await self.http.post(`${url}/password/change`, {
          oldPassword: old_pwd,
          newPassword: new_pwd,
        });
      },
    };
  }

  adminApi() {
    const self = this;
    const url = `${this.rootUrl}/admin`;
    return {
      async getAdminList() {
        return await self.http.get(`${url}`);
      },
      async inviteAdmin(user_data) {
        return await self.http.post(`${url}/invite`, user_data);
      },
      async disableAdmin(admin_id) {
        return await self.http.put(`${url}/${admin_id}/disable`, null);
      },
      async getProfList() {
        return await self.http.get(`${url}/professional`);
      },
      async inviteProf(user_data) {
        return await self.http.post(`${url}/professional/invite`, user_data);
      },
      async disableProf(prof_id) {
        return await self.http.put(
          `${url}/professional/${prof_id}/disable`,
          null
        );
      },
    };
  }

  generalDataApi() {
    const self = this;
    const url = `${this.rootUrl}/gen-data`;
    return {
      async dimensions() {
        return await self.http.get(`${url}/toolbox/dimension`);
      },
      async getADiversion() {
        return await self.http.post(`${url}/diversion/suggest`, null);
      },
      async licensureStates() {
        return await self.http.get(`${url}/licensure/states`);
      },
      async licensureTopics() {
        return await self.http.get(`${url}/licensure/topics`);
      },
      async credentialingStates() {
        return {
          data: [
            {
              id: 'IN',
              name: 'Indiana',
              links: [
                {
                  id: 0,
                  description: 'Indiana Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Indiana Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Indiana Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'KY',
              name: 'Kentucky',
              links: [
                {
                  id: 0,
                  description: 'Kentucky Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Kentucky Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Kentucky Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'MI',
              name: 'Michigan',
              links: [
                {
                  id: 0,
                  description: 'Michigan Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Michigan Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Michigan Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'OH',
              name: 'Ohio',
              links: [
                {
                  id: 0,
                  description: 'Ohio Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Ohio Link 2',
                  url: 'https://amazon.com',
                },
                { id: 2, description: 'Ohio Link 3', url: 'https://apple.com' },
              ],
            },
          ],
        };
      },
      async credentialingTopics() {
        return {
          data: [
            {
              topicId: 0,
              description: 'General Credentialing Link 1',
              url: 'https://google.com',
            },
            {
              topicId: 1,
              description: 'General Credentialing Link 2',
              url: 'https://youtube.com',
            },
            {
              topicId: 2,
              description: 'General Credentialing Link 3',
              url: 'https://hulu.com',
            },
            {
              topicId: 3,
              description: 'General Credentialing Link 4',
              url: 'https://hulu.com',
            },
            {
              topicId: 4,
              description: 'General Credentialing Link 5',
              url: 'https://hulu.com',
            },
            {
              topicId: 5,
              description: 'General Credentialing Link 6',
              url: 'https://hulu.com',
            },
          ],
        };
      },
      async grantWritingStates() {
        return {
          data: [
            {
              id: 'IN',
              name: 'Indiana',
              links: [
                {
                  id: 0,
                  description: 'Indiana Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Indiana Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Indiana Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'KY',
              name: 'Kentucky',
              links: [
                {
                  id: 0,
                  description: 'Kentucky Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Kentucky Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Kentucky Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'MI',
              name: 'Michigan',
              links: [
                {
                  id: 0,
                  description: 'Michigan Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Michigan Link 2',
                  url: 'https://amazon.com',
                },
                {
                  id: 2,
                  description: 'Michigan Link 3',
                  url: 'https://apple.com',
                },
              ],
            },
            {
              id: 'OH',
              name: 'Ohio',
              links: [
                {
                  id: 0,
                  description: 'Ohio Link 1',
                  url: 'https://google.com',
                },
                {
                  id: 1,
                  description: 'Ohio Link 2',
                  url: 'https://amazon.com',
                },
                { id: 2, description: 'Ohio Link 3', url: 'https://apple.com' },
              ],
            },
          ],
        };
      },
      async grantWritingTopics() {
        return {
          data: [
            {
              topicId: 0,
              description: 'General Grant Writing Link 1',
              url: 'https://google.com',
            },
            {
              topicId: 1,
              description: 'General Grant Writing Link 2',
              url: 'https://youtube.com',
            },
            {
              topicId: 2,
              description: 'General Grant Writing Link 3',
              url: 'https://hulu.com',
            },
            {
              topicId: 3,
              description: 'General Grant Writing Link 4',
              url: 'https://hulu.com',
            },
            {
              topicId: 4,
              description: 'General Grant Writing Link 5',
              url: 'https://hulu.com',
            },
            {
              topicId: 5,
              description: 'General Grant Writing Link 6',
              url: 'https://hulu.com',
            },
          ],
        };
      },
      async timezones() {
        return await self.http.get(`${url}/timezones`);
      },
      async specialties() {
        return await self.http.get(`${url}/specialties`);
      },
    };
  }

  profApi() {
    const self = this;
    const url = `${this.rootUrl}/professional`;
    return {
      async getProfInfo() {
        return await self.http.get(`${url}`);
      },
      async getPatientInfo(patientId) {
        return await self.http.get(`${url}/patient/${patientId}`);
      },
      async getPrompts(patientId, pg_num, pg_sz) {
        return await self.http.get(
          `${url}/prompts/${patientId}?pg_num=${pg_num}&pg_sz=${pg_sz}`
        );
      },
      async addPrompt(patientId, promptType, expires, prompt) {
        return await self.http.post(`${url}/prompts/${patientId}`, {
          promptType: promptType,
          expiresOnUtc: expires,
          prompt: prompt,
        });
      },
      async getPatientGoals(patientId, pg_num, pg_sz, filter, sort) {
        return await self.http.get(
          `${url}/goals/${patientId}?pg_num=${pg_num}&pg_sz=${pg_sz}&filter=${filter}&sort=${sort}`
        );
      },
      async getPatientActions(patientId, pg_num, pg_sz, filter, sort) {
        return await self.http.get(
          `${url}/goals/${patientId}/actions?pg_num=${pg_num}&pg_sz=${pg_sz}&filter=${filter}&sort=${sort}`
        );
      },
      // adds a new goal to patient's goal list
      async addCustomPatientGoal(patientId, title, description) {
        return await self.http.post(`${url}/goals/${patientId}`, {
          title: title,
          description: description,
        });
      },
      // adds a template goal to a patient's goal list
      async addGoalFromTemplate(patientId, templateGoalId) {
        return await self.http.post(
          `${url}/goals/${patientId}/template/${templateGoalId}`
        );
      },
      // adds a new action to an existing patient-goal
      async addCustomPatientAction(
        patientId,
        goalId,
        title,
        description,
        dimensionId,
        dueDate
      ) {
        return await self.http.post(`${url}/goals/${patientId}/${goalId}`, {
          title: title,
          description: description,
          dimensionId: dimensionId,
          dueOnUtc: dueDate,
        });
      },
      async addActionFromTemplate(
        patientId,
        goalId,
        templateActionId,
        dimensionId,
        dueDate
      ) {
        return await self.http.post(
          `${url}/goals/${patientId}/${goalId}/action/template`,
          {
            actionTemplateId: templateActionId,
            dimensionId: dimensionId,
            dueOnUtc: dueDate,
          }
        );
      },
      async getTemplateGoals() {
        return await self.http.get(`${url}/goals/template/goals`);
      },
      async getTemplateActions() {
        return await self.http.get(`${url}/goals/template/actions`);
      },
      async createActionTemplate(actionName, description, dimensionId) {
        return await self.http.post(`${url}/goals/template/actions`, {
          title: actionName,
          description: description,
          defaultDimensionId: dimensionId,
        });
      },
      async editActionTemplate(updatedAction) {
        return await self.http.put(
          `${url}/goals/template/actions/${updatedAction.id}`,
          {
            title: updatedAction.title,
            description: updatedAction.description,
            defaultDimensionId: updatedAction.defaultDimensionId,
          }
        );
      },
      async editGoalTemplate(updatedGoal) {
        return await self.http.put(
          `${url}/goals/template/goals/${updatedGoal.id}`,
          {
            title: updatedGoal.title,
            description: updatedGoal.description,
          }
        );
      },
      async editPatientAction(patientId, goalId, updatedAction) {
        return await self.http.put(
          `${url}/goals/${patientId}/${goalId}/${updatedAction.id}`,
          {
            title: updatedAction.title,
            description: updatedAction.description,
            dimensionId: updatedAction.dimensionId,
            dueOnUtc: updatedAction.dueOnUtc,
          }
        );
      },
      async editPatientGoal(patientId, updatedGoal) {
        return await self.http.put(
          `${url}/goals/${patientId}/${updatedGoal.id}`,
          {
            title: updatedGoal.title,
            description: updatedGoal.description,
          }
        );
      },
      async templateFromPatientGoal(patientId, goalId, title, description) {
        return await self.http.post(
          `${url}/goals/${patientId}/${goalId}/goal/template`,
          {
            newTitle: title,
            newDescription: description,
          }
        );
      },
      async clonePatientGoal(patientId, goalId) {
        return await self.http.post(
          `${url}/goals/${patientId}/${goalId}/clone`
        );
      },
      async cloneActionTemplate(actionId) {
        return await self.http.post(
          `${url}/goals/template/actions/${actionId}/clone`
        );
      },
      async cloneGoalTemplate(goalId) {
        return await self.http.post(
          `${url}/goals/template/goals/${goalId}/clone`
        );
      },
      async deletePatientGoal(patientId, goalId) {
        return await self.http.delete(`${url}/goals/${patientId}/${goalId}`);
      },
      async deletePatientAction(patientId, goalId, actionId) {
        return await self.http.delete(
          `${url}/goals/${patientId}/${goalId}/${actionId}`
        );
      },
      async deleteGoalTemplate(goalId) {
        return await self.http.delete(`${url}/goals/template/goals/${goalId}`);
      },
      async deleteActionTemplate(actionId) {
        return await self.http.delete(
          `${url}/goals/template/actions/${actionId}`
        );
      },
      async getPatientOverview(patientId) {
        return await self.http.get(
          `${url}/patient/${patientId}/overview?num_days=7`
        );
      },
      async getDimensionProgress(patientId) {
        return await self.http.get(`${url}/patient/${patientId}/progress`);
      },
      async getPatientsOverview(pgNum, pgSz, filter) {
        return await self.http.get(
          `${url}/patient/overview?pg_num=${pgNum}&pg_sz=${pgSz}&filter=${filter}`
        );
      },
      async getDiversionHistory(patientId, pgsz, pgnum) {
        return await self.http.get(
          `${url}/patient/${patientId}/diversion/history?pg_sz=${pgsz}&pg_num=${pgnum}`
        );
      },
      async getActions(
        patientId,
        pgSz,
        pgNum,
        sort,
        nameFilter,
        completedFilter
      ) {
        return await self.http.get(
          `${url}/goals/${patientId}/actions?pg_sz=${pgSz}&pg_num=${pgNum}&sort=${sort}&filter=${nameFilter}&completed=${completedFilter}`
        );
      },

      async getTasks(pgSz, pgNum, titleFilter, taskOrder, completeFilter) {
        return await self.http.get(
          `${url}/tasks?pg_sz=${pgSz}&pg_num=${pgNum}&title_filter=${titleFilter}&complete_filter=${completeFilter}&task_order=${taskOrder}`
        );
      },
      async getSupervisors(
        pgSz,
        pgNum,
        lastName,
        city,
        state,
        zip,
        specialtyId
      ) {
        return await self.http.get(
          `${url}/supervisors?pg_sz=${pgSz}&pg_num=${pgNum}&lastname=${lastName}&city=${city}&state=${state}&zip=${zip}&hasSpecId=${specialtyId}`
        );
      },
      async getSpecialties(pgSz, pgNum) {
        return await self.http.get(`${url}/spec?pg_sz=${pgSz}&pg_num=${pgNum}`);
      },
      async updateSpecialty(specId, specIncluded) {
        return await self.http.post(`${url}/spec`, {
          specialtyId: specId,
          isIncluded: specIncluded,
        });
      },
      async getManagedPatients(pgSz, pgNum) {
        return await self.http.get(
          `${url}/patient?pg_sz=${pgSz}&pg_num=${pgNum}`
        );
      },
      async setPatientAcctStatus(pid, isEnabled) {
        return await self.http.put(`${url}/patient/${pid}/enable`, {
          isEnabled: isEnabled,
        });
      },
      async invitePatient(user_data) {
        return await self.http.post(`${url}/invite`, user_data);
      },
    };
  }

  userApi() {
    const self = this;
    const url = `${this.rootUrl}/user`;
    const profUrl = `${this.rootUrl}/professional`;
    const patientUrl = `${this.rootUrl}/patient`;
    const adminUrl = `${this.rootUrl}/admin`;
    const getBaseUrl = (user_type) =>
      user_type == 'professional'
        ? profUrl
        : user_type == 'administrator'
        ? adminUrl
        : patientUrl;

    return {
      async getUserStatus() {
        return await self.http.get(`${url}/status`);
      },
      async getJournalHistory(pn, psz) {
        return await self.http.get(
          `${url}/journal/curruser?pg_sz=${psz}&pg_num=${pn}`
        );
      },
      async getProfileInfo(user_type) {
        console.log(user_type);
        return await self.http.get(`${getBaseUrl(user_type)}/profile`);
      },
      async setProfileInfo(user_type, data) {
        return await self.http.put(`${getBaseUrl(user_type)}/profile`, data);
      },
      async getPatientActivities(patientId) {
        return await self.http.get(`${url}/activities/${patientId}`);
      },
      async setProfilePic(imageData) {
        return await self.http.post(`${url}/setphoto`, imageData);
      },
      async resetProfilePic() {
        return await self.http.post(`${url}/resetphoto`, null);
      },
      async checkEmailValidation(data) {
        return await self.http.post(`${url}/email/validate`, data);
      },
      async getPublicProfileInfo(uid) {
        return await self.http.get(`${url}/profile/${uid}`);
      },
      async getContacts() {
        return await self.http.get(`${url}/contacts`);
      },
      async addContact(firstName, lastName, occupation, email, phone, address) {
        return await self.http.post(`${url}/contacts`, {
          firstName: firstName,
          lastName: lastName,
          occupation: occupation,
          email: email,
          phone: phone,
          address: address,
        });
      },
      async deleteContact(contactId) {
        return await self.http.delete(`${url}/contacts/${contactId}`);
      },
      async editContact(contact) {
        return await self.http.put(`${url}/contacts/${contact.id}`, contact);
      },
      async getPublicSpecialties(profId) {
        return await self.http.get(`${url}/professional/spec/${profId}`);
      },
      async changePassword(newPwd) {
        return await self.http.put(`${url}/password/change`, {
          newPassword: newPwd,
        });
      },
      async getTasks(startDate, endDate) {
        return await self.http.get(
          `${url}/todo?startDate=${startDate}&endDate=${endDate}`
        );
      },
      async addTodo(
        title,
        description,
        dueDateUtc,
        isRecurring,
        recurringPattern,
        hasTimeValue
      ) {
        return await self.http.post(`${url}/todo`, {
          title: title,
          description: description,
          dueDateUtc: dueDateUtc,
          isRecurring: isRecurring,
          recurringPattern: recurringPattern,
          hasTimeValue: hasTimeValue,
        });
      },
      async editTodo(id, item) {
        return await self.http.put(`${url}/todo/${id}`, {
          title: item.title,
          description: item.description,
          dueDateUtc: item.dueDateUtc,
          isRecurring: item.isRecurring || false,
          recurringPattern: item.recurringPattern || '',
          hasTimeValue: item.hasTimeValue,
        });
      },
      async deleteTodo(id) {
        return await self.http.delete(`${url}/todo/${id}`);
      },
      async updateTodoCompletion(id, isCompleted) {
        return await self.http.put(`${url}/todo/${id}/completion`, {
          isCompleted: isCompleted,
        });
      },
      async getDiversions() {
        return await self.http.get(`${url}/diversions`);
      },
    };
  }

  patientApi() {
    const self = this;
    const url = `${this.rootUrl}/patient`;
    return {
      async getInfo() {
        return await self.http.get(`${url}/info`);
      },
      async getGoals(pgsz, pgnum, sort, filter) {
        return await self.http.get(
          `${url}/goals?pg_sz=${pgsz}&pg_num=${pgnum}&sort=${sort}&filter=${filter}`
        );
      },
      async completeGoalAction(id, iscompleted) {
        return await self.http.put(`${url}/goals/action/${id}/completion`, {
          isCompleted: iscompleted,
        });
      },
      async getTasks(
        pgSz,
        pgNum,
        startDate,
        endDate,
        titleFilter,
        taskOrder,
        taskFilter
      ) {
        return await self.http.get(
          `${url}/tasks?pg_sz=${pgSz}&pg_num=${pgNum}&startDate=${startDate}&endDate=${endDate}&title_filter=${titleFilter}&task_order=${taskOrder}&task_filter=${taskFilter}`
        );
      },
      async getActiveDiversions() {
        return await self.http.get(`${url}/task/diversion`);
      },
      async getDiversions() {
        return await self.http.get(`${url}/diversions`);
      },
      async addDiversion(item) {
        return await self.http.post(`${url}/diversions`, item);
      },
      async remDiversion(id) {
        return await self.http.delete(`${url}/diversions/${id}`);
      },
      async addDiversionTask(item) {
        return await self.http.post(`${url}/task/diversion`, item);
      },
      async getDiversionHistory(pgsz, pgnum) {
        return await self.http.get(
          `${url}/task/diversion/history?pg_sz=${pgsz}&pg_num=${pgnum}`
        );
      },
      async completeDiversion(id, iscompleted) {
        return await self.http.put(`${url}/task/diversion/${id}/completion`, {
          isCompleted: iscompleted,
        });
      },
      async deleteActiveDiversion(id) {
        return await self.http.delete(`${url}/task/diversion/${id}`);
      },
    };
  }

  journalApi() {
    const self = this;
    const url = `${this.rootUrl}/user/journal`;

    return {
      async getJournalHistory(
        pn,
        psz,
        from,
        to,
        shared_only,
        text_filter,
        type
      ) {
        return await self.http.get(
          `${url}/curruser/history?pg_sz=${psz}&pg_num=${pn}&from_date=${from}&to_date=${to}&shared_filter=${shared_only}&text_filter=${text_filter}&type=${type}`
        );
      },
      async getSharedJournalEntries(patientId, pn, psz, filter) {
        return await self.http.get(
          `${url}/${patientId}?pg_num=${pn}&pg_sz=${psz}&filter=${filter}`
        );
      },
      async getTodaysJournal() {
        const dt = new Date().toISOString();
        return await self.http.get(`${url}/curruser/today?datetime=${dt}`);
      },
      getDailyThought(journal) {
        const entry = journal.entries.find(
          (val) => val.entryType === 'DailyThought'
        );
        // return current if we already have one
        if (entry) return JSON.parse(JSON.stringify(entry));
        // if not return a new one
        return {
          id: 0,
          entryType: 'DailyThought',
          prompt: '',
          response: '',
          isShared: false,
        };
      },

      async getSystemPrompt(journal) {
        const entry = journal.entries.find(
          (val) => val.entryType === 'SystemPrompt'
        );
        // return most recently edited system prompt entry, if one exists
        if (entry) return JSON.parse(JSON.stringify(entry));
        // if not return a new one
        return await this.createNewSystemPrompt();
      },
      async createNewSystemPrompt() {
        const msg = await self.http.post(`${url}/sys-prompt`, null); // get a new random prompt
        return {
          id: 0,
          entryType: 'SystemPrompt',
          prompt: msg.data.message,
          response: '<p><br></p>',
          isShared: false,
        };
      },
      getGratitude(journal) {
        const entry = journal.entries.find(
          (val) => val.entryType === 'Gratitude'
        );
        // return current if we already have one
        if (entry) return JSON.parse(JSON.stringify(entry));
        // if not return a new one
        return {
          id: 0,
          entryType: 'Gratitude',
          prompt: '',
          response: '',
          isShared: false,
        };
      },
      getTrauma(journal) {
        const entry = journal.entries.find((val) => val.entryType === 'Trauma');
        // return current if we already have one
        if (entry) return JSON.parse(JSON.stringify(entry));
        // if not return a new one
        return {
          id: 0,
          entryType: 'Trauma',
          prompt: '',
          response: '',
          isShared: false,
        };
      },
      async burnTrauma(journalId, entry) {
        return await self.http.delete(
          `${url}/curruser/burn/${journalId}/${entry.id}`
        );
      },
      async updateEntry(journalId, entry) {
        return await self.http.put(`${url}/curruser/${journalId}`, entry);
      },
      async deleteEntry(journalId, entry) {
        entry.response = null;
        entry.isShared = false;
        return await self.http.put(`${url}/curruser/${journalId}`, entry);
      },
    };
  }

  lifeplanApi() {
    const self = this;
    const url = `${this.rootUrl}/user`;

    return {
      async getLifePlan() {
        return await self.http.get(`${url}/lifeplan/curruser`);
      },
      async saveLifePlan(data) {
        return await self.http.put(`${url}/lifeplan/curruser`, data);
      },

      async getBucketList() {
        return await self.http.get(`${url}/bucket-list/curruser`);
      },
      async addBucketListItem(item) {
        return await self.http.post(`${url}/bucket-list/curruser`, item);
      },
      async updateBucketListItem(item) {
        return await self.http.put(
          `${url}/bucket-list/curruser/${item.id}`,
          item
        );
      },
      async removeBucketListItem(item) {
        return await self.http.delete(`${url}/bucket-list/curruser/${item.id}`);
      },
      async getPatientLifePlan(patientId) {
        return await self.http.get(`${url}/lifeplan/${patientId}`);
      },
      async getPatientBucketList(patientId) {
        return await self.http.get(`${url}/bucket-list/${patientId}`);
      },
    };
  }

  contractsApi() {
    const self = this;
    const url = `${this.rootUrl}/user/contracts`;
    return {
      async getContractList(active_only, pn, psz, title_filter) {
        let list_url = `${url}/curruser?active_only=${active_only}&pg_sz=${psz}&pg_num=${pn}`;
        if (title_filter) list_url = `${list_url}&filter=${title_filter}`;
        return await self.http.get(list_url);
      },
      async createContract() {
        return {
          data: {
            id: 0,
            title: '',
            contract: '',
            appendix: '',
            isSigned: false,
            isActive: true,
          },
        };
      },
      async getContract(id) {
        return await self.http.get(`${url}/curruser/${id}`);
      },
      async updateContract(contract) {
        if (contract.id <= 0) {
          return await self.http.post(`${url}/curruser`, contract);
        }
        return await self.http.put(`${url}/curruser/${contract.id}`, contract);
      },
      async getLastUpdatedContract() {
        var retval = await self.http.get(`${url}/curruser/last`);
        if (!retval.data) {
          return await this.createContract();
        }
        return retval;
      },
      async getPatientContracts(patientId, pn, psz, title_filter) {
        return await self.http.get(
          `${url}/${patientId}?pg_num=${pn}&pg_sz=${psz}&filter=${title_filter}`
        );
      },
      async getPatientContract(patientId, contractId) {
        return await self.http.get(`${url}/${patientId}/${contractId}`);
      },
    };
  }

  messageApi() {
    const self = this;
    const url = `${this.rootUrl}/message`;
    return {
      async getInbox(pgNum, pgSz, filter, unreadOnly, sortDesc, senderFilter) {
        return await self.http.get(
          `${url}/curruser?pgnum=${pgNum}&pgsz=${pgSz}&subject_filter=${filter}&unread_only=${unreadOnly}&sort_desc=${sortDesc}&sent_by=${senderFilter}`
        );
      },
      async getSent(pgNum, pgSz, filter, sortDesc, recipientFilter) {
        return await self.http.get(
          `${url}/curruser?srch_inbox=false&pgnum=${pgNum}&pgsz=${pgSz}&subject_filter=${filter}&sort_desc=${sortDesc}&recvd_by=${recipientFilter}`
        );
      },
      async getContacts() {
        return await self.http.get(`${url}/contacts`);
      },
      async sendMessage(recipientIds, subject, body) {
        return await self.http.post(`${url}`, {
          recipientIds: recipientIds,
          subject: subject,
          body: body,
        });
      },
      async getMessageDetails(messageId) {
        return await self.http.get(`${url}/${messageId}`);
      },
    };
  }

  testApi() {
    const self = this;
    const url = `${this.rootUrl}/test`;
    return {
      async expire() {
        return await self.http.put(`${url}/logout`);
      },
    };
  }
}

export default {
  token(state) {
    return state.token;
  },
  isAuthed(state) {
    return state.token === null || state.token === 'null' ? false : true;
  },
  userType(state) {
    return state.userType;
  },
  userId(state) {
    return state.userId;
  },
  fullName(state) {
    return state.fullName;
  },
  userStatus(state) {
    return state.userStatus;
  }
};

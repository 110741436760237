const NewPatientMaster = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/NewPatientMaster.vue'
  );
const NewPatientHome = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/PatientHome.vue'
  );

const Messages = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/messaging/MessagesView.vue'
  );
const Diversions = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/diversions/DiversionsView.vue'
  );
const Journal = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/journal/JournalView.vue'
  );
const LifePlan = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/life-plan/LifePlanView.vue'
  );
const Contracts = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/contracts/ContractsView.vue'
  );
const PublicProProfile = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/public-profiles/pro/ProfProfileView.vue'
  );
  const SelfCare = () =>
    import(
      /* webpackChunkName: "bh" */ '../../views/bh2/patient/SelfCare.vue'
    );

const patient_routes = {
  path: 'patient',
  name: 'patient',
  component: NewPatientMaster,
  children: [
    {
      path: '',
      name: 'patient-home',
      meta: {
        user_type: 'patient',
        folder: 'Home',
        text: 'Home',
      },
      component: NewPatientHome,
    },
    {
      path: 'messages',
      name: 'patient-msg',
      meta: {
        user_type: 'patient',
      },
      component: Messages,
    },
    {
      path: 'diversions',
      name: 'patient-diversions',
      meta: {
        user_type: 'patient',
      },
      component: Diversions,
    },
    {
      path: 'journal',
      name: 'patient-journal',
      meta: {
        user_type: 'patient',
      },
      component: Journal,
    },
    {
      path: 'selfcare',
      name: 'patient-self-care',
      meta: {
        user_type: 'patient',
      },
      component: SelfCare,
    },
    {
      path: 'lifeplan',
      name: 'patient-lifeplan',
      meta: {
        user_type: 'patient',
      },
      component: LifePlan,
    },
    {
      path: 'contracts',
      name: 'patient-contracts',
      meta: {
        user_type: 'patient',
      },
      component: Contracts,
    },
    {
      path: 'view-profile/:uid',
      name: 'patient-view-pro',
      component: PublicProProfile,
    },
  ],
};

export default patient_routes;

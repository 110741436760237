import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/index.js';

const LoginMaster = () =>
  import(/* webpackChunkName: "login" */ '../../views/auth2/LoginMaster.vue');
const LoginDev = () =>
  import(/* webpackChunkName: "login" */ '../../views/auth2/LoginDev.vue');
const ExternalLogin = () =>
  import(/* webpackChunkName: "login" */ '../../views/auth2/LoginSso.vue');
const VerifyExtnUser = () =>
  import(/* webpackChunkName: "login" */ '../../views/auth2/VerifyExtn.vue');

import app_bh_routes from './bh-routes.js';

const loginComponent =
  process.env.VUE_APP_LOGIN_SSO == 'true' ? ExternalLogin : LoginDev;

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', redirect: '/bh' },
    {
      path: '/',
      component: LoginMaster,
      children: [
        {
          path: 'login',
          name: 'login',
          meta: { noAuth: true },
          component: loginComponent,
        },
        {
          path: 'verify',
          name: 'verify-extn',
          meta: { noAuth: true },
          component: VerifyExtnUser,
        },
      ],
    },
    app_bh_routes,
  ],
});

router.beforeEach(function (to) {
  if (!to.meta.noAuth && !store.getters.isAuthed) {
    return { name: 'login' };
  } else {
    if (to.name == 'home') {
      // if auth0 codes are still in the url get rid of them
      if (window.location.href.includes('?code=')) {
        window.location = `${window.location.protocol}//${window.location.host}`;
      }
      if (store.getters.userType == 'patient') {
        return { name: 'patient-home' };
      } else if (store.getters.userType == 'administrator') {
        return { name: 'admin-home' };
      } else {
        return { name: 'pro-home' };
      }
    } else {
      return true;
    }
  }
});

export default router;

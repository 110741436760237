<template>
  <div class="d-flex flex-column vh-100">
    <router-view></router-view>
    <bh-footer />
  </div>
</template>

<script>
import { inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import BhFooter from './views/bh2/shared/Footer.vue';

export default {
  components: {
    BhFooter,
  },
  setup() {
    const store = useStore();
    const api = inject('api');
    const is_logged_in = ref(false);
    const userType = ref('');

    const fetchStatus = async () => {
      const response = await api.userApi().getUserStatus();
      if (response && response.data) {
        store.commit('setUserStatus', response.data);
      } else store.commit('setUserStatus', null);
    };

    const appWorker = async () => {
      if (
        process.env.VUE_APP_SERVER_POLLING &&
        process.env.VUE_APP_SERVER_POLLING === 'on'
      ) {
        let polling_ms = 30000;
        if (
          process.env.VUE_APP_POLLING_PERIOD &&
          process.env.VUE_APP_POLLING_PERIOD > 999
        ) {
          polling_ms = process.env.VUE_APP_POLLING_PERIOD;
        }
        setInterval(async () => {
          if (is_logged_in.value == true) {
            await fetchStatus();
          } else store.commit('setUserStatus', null);
        }, polling_ms);
      }
    };

    const updateBody = async () => {
      if (is_logged_in.value)
        document.body.className = userType.value;
      else
        document.body.className = "";
    }

    watch(
      () => store.getters.isAuthed,
      async (newVal) => {
        //console.log('newVal, oldVal', newVal, oldVal);
        is_logged_in.value = newVal;
        userType.value = store.getters.userType;
        
        updateBody();

        if (is_logged_in.value) {
          await fetchStatus();
        }else
          document.body.className = "";
      }
    );

    onBeforeMount(async () => {
      is_logged_in.value = store.getters.isAuthed;
      userType.value = store.getters.userType;

      updateBody();
      
      if (is_logged_in.value) {
        await fetchStatus();
      }
      appWorker();
    });
    return {
      is_logged_in,
    };
  },
};
</script>
<style>

body.professional {
    background-color:rgb(227 205 159) !important;
}
body.patient {
    background-color: #fff7ee !important;
}
</style>
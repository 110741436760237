import { createStore } from 'vuex';

import global from './global/index.js';
import bh from './bh/index.js';

const store = createStore({
  modules: {
    global,
    bh,
  },
});

export default store;

import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export default {
  state() {
    return {
      token: sessionStorage.getItem('token') || null,
      userType: sessionStorage.getItem('userType') || null,
      userId: sessionStorage.getItem('userId') || null,
      fullName: sessionStorage.getItem('fullName') || null,
      userStatus: null
    };
  },
  actions,
  getters,
  mutations,
};

export default {
  login(context, payload) {
    sessionStorage.setItem('token', payload.token);
    context.commit('setToken', {
      token: payload.token,
    });
    sessionStorage.setItem('userType', payload.userType);
    context.commit('setUserType', {
      userType: payload.userType,
    });
    sessionStorage.setItem('userId', payload.userId);
    context.commit('setUserId', {
      userId: payload.userId,
    });
  },
  logOut(context) {
    sessionStorage.setItem('token', null);
    context.commit('setToken', {
      token: null,
    });
    sessionStorage.setItem('userId', null);
    context.commit('setUserId', {
      userId: null,
    });
    sessionStorage.setItem('fullName', null);
    context.commit('setFullName', {
      fullName: null,
    });
  },
  updateUserType(context, payload) {
    sessionStorage.setItem('userType', payload.userType);
    context.commit('setUserType', payload);
  },
  updateFullname(context, payload) {
    let name = `${payload.firstName}`;
    if (payload.middleInitial) {
      name = `${name} ${payload.middleInitial}. ${payload.lastName}`;
    } else {
      name = `${name} ${payload.lastName}`;
    }
    sessionStorage.setItem('fullName', name);
    context.commit('setFullName', {
      fullName: name,
    });
  },
};

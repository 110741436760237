const AdminMaster = () =>
  import(
    /* webpackChunkName: "admin" */ '../../views/bh2/admin/AdminMaster.vue'
  );
const AdminProfs = () =>
  import(
    /* webpackChunkName: "admin" */ '../../views/bh2/admin/ProfMgmt.vue'
  );
const AdminAdmins = () =>
  import(
    /* webpackChunkName: "admin" */ '../../views/bh2/admin/AdminMgmt.vue'
  );

const admin_routes = {
  path: 'admin',
  component: AdminMaster,
  children: [
    {
      path: '',
      name: 'admin-home',
      redirect: { name: 'admin-profs' }
    },
    {
      path: 'profs',
      name: 'admin-profs',
      meta: {
        user_type: 'administrator',
        folder: 'Home',
        text: 'Home',
      },
      component: AdminProfs,
    },
    {
      path: 'admins',
      name: 'admin-admins',
      meta: {
        user_type: 'administrator',
        folder: 'Home',
        text: 'Home',
      },
      component: AdminAdmins,
    },
  ]
};

export default [admin_routes];
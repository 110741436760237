<template>
  <footer class="mt-auto">
    <!-- Footer navigation -->
    <div class="container-fluid bg-dark px-0 mt-4">
      <nav class="navbar navbar-expand navbar-dark small">
        <div class="container-fluid">
          <span class="small text-muted">{{ vers }}</span>
          <ul class="navbar-nav mx-auto mx-md-0 ms-md-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://www.recovery4lifellc.com/careers" >Work With Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.thecolumbuswellnesscenter.com/staff" >Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Legal text  -->
    <div class="col" data-v-a4585250="">
      <p
        class="small mx-auto p-2 text-md-center text-secondary"
        data-v-a4585250=""
      >
        <strong class="text-danger" data-v-a4585250="">Disclaimer:</strong>
        Communications between you and BHealthyForLife and information stored by
        using this site are protected by our
        <a href="https://www.recovery4lifellc.com/privacy-policy"  data-v-a4585250="">privacy&nbsp;policy</a>. Access to this
        website is subject to our
        <a href="https://www.recovery4lifellc.com/privacy-policy"  data-v-a4585250="">terms&nbsp;of&nbsp;service</a>.
      </p>
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue';
export default {
  setup() {
    const vers = computed(() => {
      return `${process.env.VUE_APP_VERSION}`;
    });

    return { vers };
  },
};
</script>
<style scoped>
body.professional .navbar {
  background-color: rgb(240 190 93);
  box-shadow: 0 0 2px #ffaa00;
  font-weight:600;
}
body.patient .navbar a, span {
  color:white !important;
}
.navbar {
  font-weight:600;
}
body.professional .nav-link {
  color:#212121;
}
body.professional .small {
  color:#212121 !important;
}

body.professional .text-secondary{
  color:#000 !important;
}
</style>
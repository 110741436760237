// Pro Home Routes
const NewProfessionalMaster = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/professional/NewProfessionalMaster.vue'
  );
const ProHome = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/professional/ProHome.vue'
  );
const PatientsView = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/professional/patients/PatientsView.vue'
  );
const PracticeView = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/professional/practice/PracticeView.vue'
  );
const Messages = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/messaging/MessagesView.vue'
  );
const AddActionTemplate = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/goals-actions/AddActionTemplate.vue'
  );

// Patient Details Routes
const PatientDetailsMaster = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/patient-details/PatientDetailsMaster.vue'
  );
const PatientDetails = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/patient-details/PatientDetails.vue'
  );
const AddGoal = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/patient-details/goals-details/patient-goals/AddGoal.vue'
  );
const AddAction = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/patient-details/goals-details/patient-goals/AddAction.vue'
  );
const PatientContract = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/patients/patient-details/contracts-details/PatientContract.vue'
  );
const PublicProProfile = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/public-profiles/pro/ProfProfileView.vue'
  );
const PublicPatientProfile = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/public-profiles/patient/PatientProfileView.vue'
  );
const ProSupervision = () => 
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/practice/pro-supervision/ProSupervision.vue'
  );
const ProContacts = () => 
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/professional/practice/pro-contacts/ProContacts.vue'
  );

// My Care Components
const Journal = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/journal/JournalView.vue'
  );
const LifePlan = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/life-plan/LifePlanView.vue'
  );
const Contracts = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/contracts/ContractsView.vue'
  );
const Diversions = () =>
  import(
    /* webpackChunkName: "patient" */ '../../views/bh2/patient/diversions/DiversionsView.vue'
  );

const pro_home_routes = [
  {
    path: 'pro',
    component: NewProfessionalMaster,
    children: [
      {
        path: '',
        name: 'pro-home',
        component: ProHome
      },
      {
        path: 'patients',
        name: 'patients-view',
        meta: {
          user_type: 'professional',
          folder: 'Home',
          text: 'Home',
        },
        component: PatientsView,
      },
      {
        path: 'practice',
        name: 'practice-view',
        meta: {
          user_type: 'professional',
        },
        component: PracticeView,
      },
      {
        path: 'messages',
        name: 'pro-msg',
        meta: {
          user_type: 'professional',
        },
        component: Messages,
      },
      {
        path: 'add-action-template',
        name: 'add-action-template',
        component: AddActionTemplate,
      },
      {
        path: 'supervisor-profile/:uid',
        name: 'pro-view-pro',
        component: PublicProProfile,
      },
      {
        path: 'supervision',
        name: 'supervision',
        component: ProSupervision
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: ProContacts
      },
      {
        path: 'journal',
        name: 'pro-journal',
        meta: {
          user_type: 'professional',
        },
        component: Journal,
      },
      {
        path: 'lifeplan',
        name: 'pro-lifeplan',
        meta: {
          user_type: 'professional',
        },
        component: LifePlan,
      },
      {
        path: 'contracts',
        name: 'pro-contracts',
        meta: {
          user_type: 'professional',
        },
        component: Contracts,
      },
      {
        path: 'diversions',
        name: 'pro-diversions',
        meta: {
          user_type: 'professional',
        },
        component: Diversions,
      },
    ]
  },
  {
    path: 'pro/patient-details/:pid',
    component: PatientDetailsMaster,
    children: [
      {
        path: '',
        name: 'patient-details',
        component: PatientDetails,
      },
      {
        path: 'add-goal',
        name: 'add-goal',
        component: AddGoal,
      },
      {
        path: 'add-action/:gid',
        name: 'add-action',
        component: AddAction,
      },
      {
        path: 'contract/:cid',
        name: 'patient-contract',
        component: PatientContract,
      },
      {
        path: 'profile',
        name: 'pro-view-patient',
        component: PublicPatientProfile,
      },
    ],
  }
]

export default pro_home_routes;

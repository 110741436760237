import { createApp } from 'vue';

import App from './App.vue';
import store from './services/store/index.js';
import router from './services/router/router.js';
import ApiService from './services/api.js';

import VueLoading from 'vue-loading-overlay';

import '@popperjs/core';
import 'bootstrap';
import './assets/styles/main.scss';

const app = createApp(App);

app.config.globalProperties.ZoomMtg = window.ZoomMtg;

app.use(store);
app.use(router);
app.use(VueLoading);

app.provide('api', new ApiService(store.getters.token, router, store));
app.mount('#app');

// Home page
const BhMaster = () =>
  import(/* webpackChunkName: "bh" */ '../../views/bh2/BhMaster.vue');
// Profile menu
const ProfilePage = () =>
  import(
    /* webpackChunkName: "bh" */ '../../views/bh2/profile/ProfilePage.vue'
  );
const ResetPassword = () =>
  import(
      /* webpackChunkName: "bh" */ '../../views/bh2/shared/ResetPassword.vue'
  );
const ChangePassword = () =>
  import(
  /* webpackChunkName: "bh" */ '../../views/bh2/shared/ChangePassword.vue'
  );
const TodoCalendar = () =>
import(
/* webpackChunkName: "bh" */ '../../views/bh2/todo-calendar/TodoCalendar.vue'
);

const Embeded = () => import (
  /* webpackChunkName: "bh" */ '../../views/bh2/embeded/Embeded.vue'
)

import patient_routes from './patient-routes';
import pro_routes from './pro-routes';
import admin_routes from './admin-routes';

const app_bh_routes = {
  path: '/bh',
  name: 'home',
  component: BhMaster,
  children: [
    {
      path: 'user/profile',
      name: 'user-profile',
      component: ProfilePage,
    },
    {
      path: 'user/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },
    {
      path: 'user/change-password',
      name: 'change-password',
      component: ChangePassword
    },
    {
      path: 'todo-calendar',
      name: 'todo-calendar',
      component: TodoCalendar
    },
    {
      path: 'embeded',
      name: 'embeded',
      component: Embeded
    }
  ],
};

app_bh_routes.children.push(patient_routes);
app_bh_routes.children.push(...pro_routes);
app_bh_routes.children.push(...admin_routes);

export default app_bh_routes;
